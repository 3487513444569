@import '../../styles/colors.scss';

$headerHeight: 60rpx;

.widget.header {
  height: $headerHeight;

  .fixed {
    position: fixed;
    top: 0;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    height: $headerHeight;
    padding: 0 16rpx;
    border-bottom: 1px solid #f0f0f0;
    // background: rgba(#fff, .7);
    background: #fff;
    z-index: 9;
  }

  .logo {
    flex: 1;
    img {
      width: 124rpx;
      height: 28rpx;
    }
  }

  .ext {
    display: flex;

    .item {
      display: flex;
      align-items: center;
      margin-left: 8rpx;
      padding-left: 8rpx;
      border-left: 1px solid #f0f0f0;
  
      &:first-of-type {
        border: none;
      }
  
      &, a {
        text-decoration: none;
      }
  
      > span {
        max-width: 130rpx;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  
      .icon {
        margin-left: 3rpx;
        width: 24rpx;
        height: 24rpx;
      }
    }
  }
}