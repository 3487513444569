.widget.login {
  box-sizing: border-box;
  width: 380rpx;
  padding: 30rpx;
  
  h1 {
    font-size: 28rpx;
    span {
      display: block;

      &.tips {
        margin-top: 10rpx;
        font-size: 14rpx;
        color: #999;
      }
    }
  }

  form {
    margin: 30rpx 0;
    li {
      position: relative;
      margin-bottom: 20rpx;
      label {
        display: block;
        font-size: 16rpx;
        margin-bottom: 10rpx;
        line-height: 1;
        color: #666;
        transition: .2s ease-out;
      }

      input {
        display: block;
        width: 100%;
      }

      &:focus-within {
        label {
          color: #333;
        }
        input {
          border-color: #333;
        }
      }
    }
    button {
      width: 100%;
    }
  }

  .change {
    font-size: 14rpx;
    margin-top: 15rpx;
    text-align: center;
    color: #999;
    a {
      border-bottom: 1px solid;
    }
  }

  .rule {
    margin: 15rpx 0;
    line-height: 1.5;
    font-size: 14rpx;
    color: #999;
    strong {
      font-weight: bold;
      color: #222;
    }
  }

  .togglePassword {
    position: absolute;
    top: 25rpx;
    right: 0;
    width: 24rpx;
    height: 24rpx;
    border: solid transparent;
    border-width: 15rpx 15rpx 15rpx 15rpx;
    img {
      width: 100%;
      height: 100%;
    }
  }
}