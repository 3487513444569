@import '../../styles/colors.scss';

.page.home {
  box-sizing: border-box;
  min-height: 100%;
  // background: linear-gradient(to top, #FFF2FA, #FFE5F5);
  background-size: 100% auto;

  display: flex;
  flex-direction: column;

  .cover {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
  }

  .swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16rpx 0;
    flex: 1;
    text-align: center;
    font-size: 24rpx;
    color: #222;
    line-height: 1;

    .swiper-pagination {
      bottom: 0!important;
    }
    .swiper-pagination-bullet-active {
      background: #fff!important;
      box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    }

    .slide {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 20rpx 16rpx;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
    }

    h1 {
      margin-bottom: 15rpx;
    }

    p {
      font-size: 12rpx;
      margin-bottom: 10rpx;
    }

    .pic {
      width: 375rpx;
      // height: 250rpx;
      padding: 20rpx 0;
      margin: auto;
      display: table-cell;
      vertical-align: middle;

      img {
        width: 75%;
        display: block;
        margin: auto;

        &.smaller {
          width: 65%;
        }
      }
    }
  }

  .order {
    font-size: 18rpx;
    padding: 0 16rpx 30rpx;

    .plans {
      box-sizing: border-box;
      border: 1px solid #f0f0f0;
      background: #fff;
      border-radius: 11rpx;
      padding: 0 10rpx;

      li {
        display: flex;
        padding: 20rpx 6rpx;
        border-bottom: 1px solid #f0f0f0;
        align-items: center;

        &:last-of-type {
          border: none;
        }

        .checkbox {
          margin: 0 10rpx 0 0;
          width: 27rpx;
          height: 27rpx;
        }

        .desc {
          flex: 1;
          text-align: right;
          color: #999;
          font-size: 14rpx;
          &.promotion {
            color: $strongColor;
          }
        }

        .title {
          min-width: 3em;
        }

        .summary {
          margin-left: 10rpx;
          font-size: 14rpx;
        }

        del {
          color: #999;
          margin-right: 5rpx;
          // font-size: 12rpx;
        }
      }

      &.disabled {
        opacity: .3;
      }
    }

    .pay {
      width: 380rpx;
      display: block;
      margin: 20rpx 0 0;

      span {
        position: relative;
      }

      del {
        position: absolute;
        white-space: nowrap;
        top: 5rpx;
        right: calc(100% + 8rpx);
        opacity: .5;
        font-size: 14rpx;
      }

      img {
        width: 18rpx;
        height: 18rpx;
        vertical-align: -2rpx;
      }
    }

    .useCoupon {
      font-size: 14rpx;
      margin-top: 20rpx;
      text-align: center;
      .app {
        margin-bottom: 10rpx;
        color: #999;
      }
      a {
        text-decoration: underline;
      }
      .tips {
        margin-top: 10rpx;
      }
    }
  }
}

.dialog .coupon {
  box-sizing: border-box;
  width: 380rpx;
  padding: 30rpx;
  h1 {
    font-size: 28rpx;
    margin-bottom: 20rpx;
  }
  label {
    font-size: 12rpx;
    font-weight: bold;
    color: #222;
    margin-bottom: 5rpx;
    display: block;
  }
  input {
    width: 100%;
    &::placeholder {
      color: #ccc;
    }
    &:focus {
      border-color: #222;
    }
  }
  button {
    width: 100%;
    margin-top: 20rpx;
  }

  .how {
    margin: 20rpx 0 0;
    font-size: 14rpx;
    color: #999;
    line-height: 1.6;
    strong {
      font-weight: bold;
      color: #333;
    }
  }

  .split {
    height: 1px;
    background: #f0f0f0;
    margin: 10rpx 0;
  }
}