@import './colors.scss';

a.button,
button {
  display: inline-block;
  box-sizing: border-box;
  font-size: 20rpx;
  line-height: 1;
  padding: 14rpx;
  border: none;
  color: #fff;
  border-radius: 6rpx;
  background: $brandColor;
  outline: none;

  // &:disabled {
  //   opacity: .3;
  // }
}

input {
  box-sizing: border-box;
  font-size: 20rpx;
  border: 1px solid #E0E0E0;
  background: #fff;
  border-radius: 6rpx;
  padding: 10rpx 6rpx;
  line-height: 30rpx;
  outline: none;
}
input.hidden{
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}