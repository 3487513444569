@import '../../styles/colors.scss';

.page.user {
  .tips {
    font-size: 12rpx;
    line-height: 1.6;
    padding: 10rpx 20rpx;
    color: #c30;
    background: #ffffe1;
  }

  .box {
    margin: 16rpx;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    padding: 20rpx;
    border-radius: 11rpx;
    background: #fff;

    h1 {
      font-size: 24rpx;
      line-height: 1;
      margin-bottom: 20rpx;
    }

    label {
      display: block;
      margin-bottom: 10rpx;
      font-size: 12rpx;
      font-weight: bold;
      color: #999;
      &.warning {
        color: $strongColor;
      }
    }

    dl {
      line-height: 1.6;
      font-size: 14rpx;

      dt {
        color: #999;
      }

      dt~dt {
        margin-top: 5rpx;
      }
      
      dd {
        margin-bottom: 10rpx;
        color: #999;
      }
    }

    &.account {
      ul {
        display: flex;
        li {
          &:last-of-type {
            padding-right: 0;
            margin-right: 0;
            border: 0;
          }
          &:first-of-type {
            flex: 1;
            // padding-right: 10rpx;
            word-break: break-all;
          }
        }
        span {
          display: block;
          font-size: 24rpx;
        }
      }
    }
  
    .btn {
      display: inline-block;
      background: $brandColor;
      color: #fff;
      font-size: 16rpx;
      line-height: 1;
      padding: 8rpx 14rpx;
      border-radius: 6rpx;
      text-decoration: none;
      vertical-align: middle;
    }

    .empty {
      width: 100%;
      height: 200rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f8f8f8;
      border-radius: 11rpx;
      
      .btn {
        margin-top: 15rpx;
      }
    }

    &.plan {
      .content {
        .summary {
          .item {
            margin-bottom: 20rpx;
            &.date {
              font-size: 28rpx;
            }
            span {
              vertical-align: middle;
            }
          }
        }

        .subcription {
          margin: 20rpx 0;
          .input {
            margin-top: 10rpx;
            position: relative;
            input {
              box-sizing: border-box;
              width: 100%;
              padding: 12rpx 90rpx 12rpx 12rpx;
              font-size: 16rpx;
              border: 1px solid #e0e0e0;
              background: #f8f8f8;
              border-radius: 6rpx;
              outline: none;
              cursor: pointer;
              text-overflow: ellipsis;
            }
            .copy {
              display: flex;
              align-items: center;
              align-content: center;
              position: absolute;
              font-size: 12rpx;
              right: 0;
              top: 0;
              bottom: 0;
              padding: 0 12rpx;
              font-weight: bold;
              white-space: nowrap;

              .success {
                color: #77BA45;
                fill: #77BA45;
              }

              .icon {
                width: 16rpx;
                height: 16rpx;
                display: inline-block;
                vertical-align: -3rpx;
                cursor: pointer;
                svg {
                  display: block;
                }
              }

              span {
                margin-left: 10rpx;
              }
            }
          }
          .tips {
            margin-top: 10rpx;
            font-size: 12rpx;
            color: #999;
          }
        }

        .btn {
          margin-left: 20rpx;
        }

        .progress {
          margin: 10rpx 0;
          display: flex;
          align-items: center;

          .bar {
            flex: 1;
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
            height: 15rpx;
            padding: 1px;
            border-radius: 7rpx;
            overflow: hidden;

            var {
              // display: block;
              float: left;
              height: 100%;
              background: #333;
              border-radius: 7rpx;
            }
          }
          .value {
            font-size: 12rpx;
            margin-left: 10rpx;
          }
        }

        .tips {
          margin-bottom: 5rpx;
        }

        .reset {
          font-size: 12rpx;
          color: #999;

          a {
            color: #B90610;
            border-bottom: 1px solid;
            &:hover {
              color: #E3000D;
            }
          }
        }
      }
    }

    &.stat {
      .tips {
        margin-top:20rpx;
        font-size: 14rpx;
        text-align: center;
        color: #999;
      }
    }

    &.logout {
      padding: 0;
      border: none;
      button {
        width: 100%;
        background: #333;
      }
    }

    .split {
      display: block;
      overflow: hidden;
      height: 1px;
      background: #f0f0f0;
      margin: 20rpx 0;
    }
  }
}