@media screen and (min-width: 1025px) {
  html {
    .page {
      transform: scale(0.5);
      transform-origin: 50% 0;
    }
    .widget.dialog {
      .body {
        transform: scale(.3);
      }
    }
  }
}