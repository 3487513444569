.dialog .landing {
  box-sizing: border-box;
  width: 380rpx;
  padding: 30rpx;
  text-align: center;

  h1 {
    padding: 20rpx;
    background: #ffffe1;
    color: #f5ba45;
    border: 1px solid;
    overflow: hidden;
    border-radius: 10rpx;
    font-size: 28rpx;
    margin: 20rpx 0;
    font-weight: bold;
  }

  h2 {
    font-size: 20rpx;
    font-weight: bold;
  }

  .tips {
    text-align: left;
    margin: 20rpx 0 0;

    li {
      margin-bottom: 10rpx;
      color: #999;

      &::before {
        content: '·';
        float: left;
        margin-right: 3rpx;
      }
    }
  }

  .confirm {
    margin: 20rpx 0;
    width: 100%;
  }

  .later {
  }
}