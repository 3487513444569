.page.result {
  .btn,
  .gotoUser {
    text-align: center;
  }

  .summary {
    text-align: center;
    padding: 70rpx 16rpx;
    h1 {
      margin-top: 20rpx;
      font-size: 36rpx;
      line-height: 40rpx;
    }
    img {
      display: block;
      margin: auto;
      width: 78rpx;
      height: 78rpx;
    }
    p {
      font-size: 14rpx;
      line-height: 20rpx;
      color: #999;
      overflow: hidden;
      strong {
        color: #333;
        font-weight: bold;
      }
    }
  }

  .notice {
    margin: 40rpx 16rpx 16rpx;
    padding: 16rpx;
    font-size: 14rpx;
    border: 1px solid #f0f0f0;
    background: #fff;
    color: #999;
    border-radius: 11rpx;
    line-height: 1.6;
    strong {
      color: #333;
      font-weight: bold;
    }
    .split {
      height: 1px;
      background: #f0f0f0;
      margin: 10rpx 0;
    }
  }

  .tips {
    padding: 0 16rpx;
    text-align: center;
    font-size: 14rpx;
    line-height: 20rpx;
    color: #999;

    strong {
      color: #333;
      font-weight: bold;
    }

    & ~ .tips {
      margin-top: 10rpx;
    }
  }

  .contact {
    margin: 50rpx auto 0;
  }

  .btn {
    margin: 20rpx 0 0;
  }

  .gotoUser {
    margin-top: 20rpx;
  }

  .addTelegram {
    text-align: center;
    margin-top: 70rpx;
    color: #222;
    p {
      margin: 10rpx 0;
      &:first-of-type {
        margin-bottom: 15rpx;
      }
    }
    .icon {
      vertical-align: -4rpx;
      width: 20rpx;
      height: 20rpx;
      margin-right: -2rpx;
    }
    a {
      color: #666;
    }
  }
}

.dialog .help {
  box-sizing: border-box;
  width: 380rpx;
  padding: 30rpx;
  h1 {
    font-size: 28rpx;
    margin-bottom: 20rpx;
  }
  p {
    line-height: 1.5;
    margin: 20rpx 0;
    color: #999;
  }
  .import {
    margin-top: 30rpx;
    text-align: center;
    font-size: 14rpx;
    button {
      width: 100%;
    }
  }
  .telegram {
    border-top: 1px solid #f0f0f0;
    padding-top: 20rpx;
    margin-top: 20rpx;
    a {
      color: #222;
      white-space: nowrap;
    }
    .icon {
      vertical-align: -5rpx;
      width: 20rpx;
      height: 20rpx;
      margin: 0 3rpx;
    }
  }
}