@import 'reset-css';
@import './styles/widgets.scss';
@import './styles/colors.scss';
@import './styles/desktop.scss';

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'PingFangSC-Regular', 'PingFangSC', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: #f8f8f8;
  font-size: 16rpx;
}

a {
  color: $brandColor;
}

button,
input {
  appearance: none;
}