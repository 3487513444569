.widget.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;

  .close {
    svg {
      position: absolute;
      top: 8rpx;
      right: 8rpx;
      width: 32rpx;
      height: 32rpx;
      z-index: 2;
      fill: #333;
    }
  }

  .body {
    position: relative;
    z-index: 1;
    border-radius: 11rpx;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 35rpx rgba(0,0,0,.75);

    &.transparent {
      background: transparent;
      box-shadow: none;
    }
  }

  &::after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.75);
  }
}